@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
:root{
  --system-theme-primary-color: #590A35;
  --system-theme-primary-color-bolder: #420727;
  --system-theme-background-secondary-color:#FFCB00;
  --system-theme-background-primary-color:#F0F1F3;
}
body{
overflow-x: hidden !important;
font-family: Poppins !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: var(--system-theme-primary-color);
}

.simplebar-content {
  font-size: 0.8em;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar-nav::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.sidebar-nav {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.btn-primary{
  --cui-btn-bg:var(--system-theme-primary-color) !important;
  --cui-btn-border-color:var(--system-theme-primary-color) !important;
  --cui-btn-hover-bg:var(--system-theme-primary-color-bolder) !important;
  --cui-btn-hover-border-color:var(--system-theme-primary-color-bolder) !important;
  --cui-btn-disabled-bg:var(--system-theme-primary-color-bolder) !important;
  --cui-btn-disabled-border-color:var(--system-theme-primary-color-bolder) !important;
}
.small-icon {
  transform: scale(0.7);
}

.card-body {
  padding: 0 !important;
}

.header {
  padding: 0 !important;
}

.w-5 {
  width: 5% !important;
}
.w-7 {
  width: 7% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-40 {
  width: 40% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}


@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1500px !important;
    padding: 0px !important;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
    padding: 0px !important;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
    padding: 0px !important;
  }
}

.clickable-drop-id:hover{
  background-color:#3f51b5;
  cursor: pointer;
  border-radius: 10px;
}
.clicked-drop-id{
  background-color:#3f51b5;
  cursor: pointer;
  border-radius: 10px;
}
.tree-table {
  width: 100%;
}

.tree-table th 
 {
  padding: 10px;
  font-size: 13px;
}
.td-track-data {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
}
.td-track-small-data {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
  white-space: nowrap;
}

.td-track{
  border-right: 4px solid black;
  
}
.td-track::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  position: relative;
  background: #ffcb00;
  left: 0.75rem;
  right: 0;
  margin: 0 auto;
  border-radius: 100%;
  /* top: -0.55rem; */
}
.font-weight-bold{
  font-weight: bold;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.error-list {
  margin: 0;
  padding: 0;
  padding-left: 10px;
}
.error-list-item {
  font-size: .8em;
}
.error-list-title{
  font-size: .9em;
  font-weight: bold;
}
.bg-gold{
  background-color: #d4af37 !important;
}
.legend-fieldset{
  width: unset !important;
  float: unset !important;
}
.c-accordion-small-btn > button.accordion-button {
  padding: 5px;
  color: unset;
}
.dot {
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.MuiTab-textColorInherit.Mui-selected{
  background-color: var(--system-theme-background-primary-color); 
}
.MuiTableCell-head > div > select{
  color: var(--system-theme-background-secondary-color);
  text-align: center;
}
.MuiTableCell-head > div > select > option{
  color: var(--system-theme-background-secondary-color);
  text-align: center;
}
.MuiTableRow-head > th > div > span > span > svg{
  fill:#FFFFFF !important;
}
.MuiTableRow-head > th > div > button{
  color: var(--system-theme-background-secondary-color) !important;
}
.MuiTableCell-body > div > span > span > svg{
  fill:var(--system-theme-primary-color) !important;
}